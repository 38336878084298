import React, { useEffect, useState } from 'react'
import onlineCourse from '../../assets/Onlinecourse.svg';
import { Link } from 'react-router-dom';
import { Form, useFormik } from "formik";
import * as Yup from "yup";
import { Card, FormFeedback, Input, Button } from 'reactstrap';
import { CardBody, CardFooter, CardHeader } from 'react-bootstrap';
import { ApiServices, KEY } from '../ApiServices';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import exampleImage from '../../assets/favicon.png';
import NFT from '../../assets/NFT-PNG.png';
import Live from '../../assets/University-Student-PNG-Pic.png';
import UPRO from '../../assets/upro.svg';
import learn from '../../assets/Education-Learning-PNG-Photo.png';

import Swal from 'sweetalert2';
import Loader from './Spinner';
import { BallTriangle } from 'react-loader-spinner';

function PaymentLogin() {
    const [loading, setLoading] = useState(false)
    const [paymentLoad, setPaymentLoad] = useState(false)

    
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
      }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            phoneNumber: '',
            email: '',
            about: '',
            profile: '',


        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            phoneNumber: Yup.number()
                .required("Phone number is required")
                .typeError("Phone must be a number")
                .test('len', 'Phone number must be between 7 and 15 digits', val => val && val.toString().length >= 7 && val.toString().length <= 15),
            email: Yup.string().email("Invalid email format").required("Email is required"),
            about: Yup.string().required("About Cryptocurrency is required"),
            profile: Yup.string().required("Current Profile is required"),

        }),
        onSubmit: (values) => {
            handlePost(values);

        },
    });
    const handlePost = async (data) => {
        setLoading(true)
        const postForm = {
            name: data.name,
            email: data.email,
            mobileNo: data.phoneNumber,
            aboutCrypto: data.about,
            currentProfile: data.profile
        }
        try {
            const response = await axios.post(ApiServices.PAYMENTFORM, postForm)
            
           

            if (response.status) {
                setLoading(false)
                openPayModal();
                validation.resetForm()
                toast.success(response.message)

            }
            else {
                toast.dismiss()
                setLoading(false)
                toast.error(response.message)
                // validation.resetForm();
            }

        } catch (error) {
            // toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


        }




    }
    
    const openPayModal = () => {
        const paymentAmount = 999 * 100;
        const options = {
            key:KEY,
            // key: "rzp_live_kPFFIx3gU0n92X",
            amount: paymentAmount, // Payment amount in paise
            name: "Probinar Pay",
            description: "Some description",
            image: {exampleImage}, // Replace with your logo path
            handler: function (response) {
                // alert(`Payment successful with ID: ${response.razorpay_payment_id}`);
                // Add your logic to handle the payment success
                
                const paymentDetails = {
                    payment_id: response.razorpay_payment_id,
                    amount: paymentAmount,
                };
                
                updateAccount(paymentDetails);
            },
            prefill: {
                name: "Nagarajan",
                contact: "6382402574",
                email: "notifications@probinar.in"
            },
            notes: {
                address: "some address"
            },
            theme: {
                color: "#F37254",
                hide_topbar: false
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    
    const updateAccount = async (payment) => {
       const postData={
        email:validation.values.email,
        paymentId:payment.payment_id,
        amount:999,
       }
       setPaymentLoad(true)
        try {
            const updateResponse = await axios.post(ApiServices.UPDATE_ACCOUNT,postData);
            if (updateResponse.data.status) {
                setPaymentLoad(false)
                // toast.success(updateResponse.data.message)
                Swal.fire({
                    title: 'Thank you',
                    html: `
                    <p style="color: black;">You are successfully enrolled in the Probinar Blockchain course. We will email your login details within 24 hours.</p>
                    <p style="color: black;">For queries, contact us between 10 AM and 6 PM at </p>
                    <a style="color: #ff6600;" href="tel:+919280232861">+91-9280232861</a>
                    `,
                    icon: 'success',
                    confirmButtonText: 'Back to Home',
                    preConfirm: () => {
                      window.location.href = '/'; 
                    }
                  });
            }else{
                toast.error(updateResponse.data.message)
                setPaymentLoad(false)
            }
        } catch (error) {
            toast.error("Internal Server Error Try Again Later")

        }
    };
  
    return (
        <div className='d-lg-flex justify-content-center align-items-center' style={{ width: "100%", height: '100vh' }}>

            {paymentLoad ? (
                     <div className="loader-container">
                     <BallTriangle
                       height="80"
                       width="80"
                       color="#ff6600"
                       ariaLabel="watch-loading"
                       wrapperStyle={{}}
                       wrapperClass=""
                       visible={true}
                     />
                   </div>
            ):(
            
            <div className='container d-lg-flex justify-content-center align-items-center'>
            <div className='col-lg-5 order-1 pt-sm-5 order-1 order-md-2'>
                    <div className=''>

                        <Card>

                            <CardHeader>
                                <h4> Register Form </h4>
                            </CardHeader>
                            <CardBody>
                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">Name</label>
                                    <Input
                                        autoComplete='off'
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        name="name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={validation.touched.name && validation.errors.name}
                                    />
                                    {validation.touched.name && validation.errors.name && (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">Phone Number</label>
                                    <Input
                                        type="text"
                                        autoComplete='off'

                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Phone number"
                                        name="phoneNumber"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.phoneNumber || ""}
                                        invalid={validation.touched.phoneNumber && validation.errors.phoneNumber}
                                    />
                                    {validation.touched.phoneNumber && validation.errors.phoneNumber && (
                                        <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">Email</label>
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        autoComplete='off'

                                        placeholder="Enter Email"
                                        name="email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={validation.touched.email && validation.errors.email}
                                    />
                                    {validation.touched.email && validation.errors.email && (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="status-field" className="form-label">Do you know about Cryptocurrency?</label>
                                    <select
                                        id="status-field"
                                        //   className="form-control"
                                        name="about"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.about || ""}
                                        className={`form-control ${validation.touched.about && validation.errors.about ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select About Cryptocurrency</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {validation.touched.about && validation.errors.about && (
                                        <FormFeedback type="invalid">{validation.errors.about}</FormFeedback>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="status-field" className="form-label">Current Profile</label>
                                    <select
                                        id="status-field"
                                        //   className="form-control"
                                        name="profile"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.profile || ""}
                                        className={`form-control ${validation.touched.profile && validation.errors.profile ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select profile</option>
                                        <option value="Student">Student</option>
                                        <option value="Employee">Employee</option>
                                        <option value="Business">Business</option>
                                        <option value="Others">Others</option>



                                    </select>
                                    {validation.touched.profile && validation.errors.profile && (
                                        <FormFeedback type="invalid">{validation.errors.profile}</FormFeedback>
                                    )}
                                </div>
                            </CardBody>
                            <CardFooter>

                                <button onClick={validation.handleSubmit} className='btn-orange rounded px-4 py-1' disabled={loading}>
                                    {loading ?(
                                        <Loader/>
                                    ):("Submit")}
                                </button>
                            </CardFooter>

                        </Card>


                    </div>
                </div>
                <div className='col-lg-6 col-md-10 order-2 order-md-1'>
                    <div className='text-center'>
                        <h1 className='homeheading text-start py-4'><b>Welcome to Probinar - Learn Smart, Earn Quick!</b></h1>
                        <p className=' text-start py-4 text-dark'>
                            Gain In-Depth Knowledge of Cryptocurrency and Blockchain Basics in Fundamentals of Virtual Assets and Blockchain Applications
                        </p>
                    </div>
                    <div className='banbt col-lg-10 col-md-12 col-sm-12 d-flex flex-column justify-content-center py-3'>

                        <div class="alert alert-primary d-flex align-items-center" role="alert">
                        <img src={exampleImage} className='mx-3' width={20} height={20}></img>
                           <h4> 40 Modules</h4>
                        </div>
                        <div class="alert alert-secondary d-flex align-items-center" role="alert">
                        <img src={NFT} className='mx-3' width={20} height={20}></img>
                        <h4> NFT Certification </h4>
                        </div>
                        <div class="alert alert-success d-flex align-items-center" role="alert">
                        <img src={Live} className='mx-3' width={20} height={20}></img>
                        <h4> Live Doubt Clearing Sessions</h4>
                        </div>
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                        <img src={UPRO} className='mx-3' width={20} height={20}></img>
                        <h4>  Year-Long Crypto Updates</h4>
                        </div>
                        <div class="alert alert-warning d-flex align-items-center" role="alert">
                        <img src={learn} className='mx-3' width={20} height={20}></img>
                        <h4>  2350+ learners</h4> 
                        </div>

                    </div>
                </div>
               
            </div>
            )}
     

        </div>
    )
}

export default PaymentLogin