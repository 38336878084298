import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'

import Navbar from '../Navbar'
import Footer from '../Footer';
// <Navbar />
// <Footer /> 

const Course = () => {
    return (
        <>
            <Helmet>
                <title> Blockchain For Beginner | Probinar</title>
                <meta name="description" content="Learn blockchain from scratch with our beginner courses. Explore key concepts and applications in an easy-to-understand, accessible format"></meta>
                <meta name="keywords" content=" Blockchain course online, online Blockchain course, blockchain development course, blockchain course for beginners,  blockchain developer courses, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners"></meta>
                <link rel="canonical" href="https://www.probinar.in/blockchain-for-beginner" />
                <meta property="og:url" content="https://www.probinar.in/blockchain-for-beginner" />

                <meta property="og:title" content="Blockchain For Beginner | Probinar" ></meta>


                <meta property="og:image" content="https://probinar-course1.s3.amazonaws.com/Course1.jpg" ></meta>
                <meta property="og:image:alt" content=" Blockchain For Beginner | Probinar" ></meta>

                <meta property="og:site_name" content=" Blockchain For Beginner | Probinar" ></meta>
                <meta property="og:description" content="Learn blockchain from scratch with our beginner courses. Explore key concepts and applications in an easy-to-understand, accessible format"></meta>
                <meta property="twitter:domain" content="https://www.probinar.in/blockchain-for-beginner"></meta>


                <meta name="twitter:title" content="Blockchain For Beginner | Probinar" ></meta>
                <meta name="twitter:description" content="Learn blockchain from scratch with our beginner courses. Explore key concepts and applications in an easy-to-understand, accessible format" ></meta>
                <meta name="twitter:image" content="https://probinar-course1.s3.amazonaws.com/Course1.jpg" ></meta>
            </Helmet>
            <Navbar />
            <div className='main-content'>
                <div className='bred'>
                    <div className='container'>
                        <div className='con'>
                            <h1>Blockchain for Beginner</h1>
                            <ul>
                                <li>Home</li>
                                <li>Blockchain for Beginner</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='blockch_cours'>
                    <div className='container'>
                        <div className='blk_ch'>
                            <div className='im_c'>
                                <img src='images/bfb.jpg' alt='Blockchain For Beginner | Probinar' draggable="false"></img>
                                <ul className='we_bs'>
                                    <li>6 Sessions</li>
                                    <li>+ Additional</li>
                                </ul>
                            </div>
                            <ul className='webs_lin'>
                                <li>Lesson </li>
                                <li>Students 60+</li>
                                <li>Beginner</li>
                            </ul>
                            <h2>Blockchain for Beginner</h2>
                            <ul className='ins_c'>
                                <li>
                                    <img src="images/profi.png" alt="profile" draggable="false"></img>
                                    <p><span>Instructor: </span><p>John</p></p>
                                </li>

                                <li> <p><span>Category: </span><p>Blockchain</p></p> </li>
                                <li> <p><span>Last Update: </span><p>20 APR 2024</p></p> </li>
                                <li> <p><span>Review: </span>
                                    <img src='images/rating.png' draggable="false"></img>
                                    <span>(5)</span>
                                </p> </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className='tabs_bl'>
                    <div className='container'>
                        <div className='tbs_con'>
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="mb-3 jds"
                            >
                                <Tab eventKey="home" title="Overview">
                                    <div className='course_cont'>
                                        <h2>Blockchain for Beginner</h2>
                                        <p>
                                            Welcome to the Cryptocurrency and Blockchain Beginner Course! This course is your gateway to understanding the exciting world of virtual assets and blockchain technology. Throughout this journey, you'll explore the history and evolution of cryptocurrencies, gaining insights into essential investment strategies to navigate the dynamic market effectively. Learn about different types of cryptocurrency wallets and exchanges, and discover how to keep your investments secure from potential risks. Dive into the process of exchanging fiat currencies for cryptocurrencies and gain practical knowledge on using cryptocurrency exchanges. Delve deep into blockchain technology, understanding its infrastructure, operations, and real-world applications across various industries. By the end of this course, you'll be equipped with the knowledge and skills to confidently navigate the cryptocurrency landscape, manage your investments, and grasp the transformative potential of blockchain technology.
                                        </p>
                                        <h2>Earn your NFT Certification</h2>
                                        <p>
                                            Upon successful completion of the course, students who have attended all live sessions and graded according to performance in all of the assessments will be awarded with a unique NFT certificate. This certificate serves as a digital badge of honor, showcasing the student's mastery of blockchain and cryptocurrency fundamentals. With this NFT certificate, students can proudly demonstrate their expertise and differentiate themselves in the rapidly evolving world of digital assets and blockchain technology.
                                        </p>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Curriculam">
                                    <div className='course_cont'>
                                        <h2>Blockchain Course Curriculum:</h2>
                                        <p>
                                            In this 6-week course, students will delve into the dynamic world of cryptocurrency and blockchain technology. Covering virtual assets, investment strategies, wallet management, exchanges, and blockchain infrastructure, participants will gain a comprehensive understanding of the field.
                                        </p>

                                        <p>
                                            Through industry-oriented examples, students will learn to identify the right coins for investment, master wallet security, and navigate cryptocurrency exchanges adeptly. Exploring the fundamentals of blockchain, including its structure, and real-world applications across industries, students will develop a solid foundation in the subject.
                                        </p>
                                        <p>
                                            Participation in live sessions is mandatory, with certificates awarded based on active engagement and performance in assessments.
                                        </p>
                                    </div>
                                </Tab>
                                <Tab eventKey="contact" title="Syllabus" className='revi'>
                                    <div className='course_cont'>

                                        <h6 className='text-dark'>CLASS I - An Overview of Cryptocurrencies and Blockchain</h6>
                                        {/* <ul>
                                            <li> 1. ABC of Blockchain – A Quick Definition.  </li>
                                            <li> 2. Blockchain Example Scenario.  </li>
                                            <li>3. Blockchain’s Chronological Timeline  </li>
                                            <li>  4. A Key Characteristic – Decentralization </li>
                                            <li> 5. Types of Blockchain  </li>
                                            <li> 6. What’s inside a block?  </li>
                                            <li> 7. Real-World uses of blockchain technology  </li>
                                            <li>8. How peer-to-peer transactions automated? - Smart contract, an automated program. </li>
                                            <li>9. Utilizations of Smart contract </li>
                                            <li> 10. Web1 vs Web2 vs Web3  </li>
                                            <li>11. Web3 – A decentralization achieving technology.  </li>
                                            <li> 12. NFT and variations between Fungible & Non-Fungible Tokens  </li>
                                            <li>13. Digital Universe – Metaverse  </li>
                                            <li> 14. The Concept of Virtual Currency</li>

                                        </ul> */}
                                        <h6 className='text-dark'>CLASS II - Cryptocurrency Explained </h6>
                                        {/* <ul>
                                            <li> 1. Brief Overview – Cryptocurrency   </li>
                                            <li> 2. Mechanism of Cryptocurrency </li>
                                            <li>3. Blockchain’s Chronological Timeline  </li>
                                            <li> 4. Perks of Cryptocurrency     </li>
                                            <li>5. Variation between Coin & Token   </li>
                                            <li> 6. Classifications of Coins & Tokens    </li>
                                            <li> 7. How a token is created? – Token Standard    </li>
                                            <li>8. The Token Abbreviation – Ticker  </li>


                                        </ul> */}
                                        <h6 className='text-dark'>CLASS III - Foundations of Blockchain infrastructure and operations  </h6>
                                        {/* <ul>
                                            <li>  1. A contract address - digital key to decentralized agreements     </li>
                                            <li> 2. Chain id & chain name- defining your place in the blockchain universe </li>
                                            <li>  3. Decoding the RPC URL   </li>
                                            <li>4. Understanding the Significance of Blockchain Explorers   </li>
                                            <li>5. Purpose and Necessity of Gas fees in Blockchain Transactions  </li>
                                            <li>6. Delving into Cryptocurrency Mining and Its Mechanisms    </li>
                                            <li> 7. Minting – Creation of Tokens     </li>
                                            <li>8. Burning Explained: Definition and Operations    </li>
                                            <li>9. Explaining Fixed Supply: Limited Coin/Token Availability </li>
                                            <li>10. The Impact of Bitcoin Halving: Scheduled Reductions and Market Trends</li>
                                        </ul> */}
                                        <h6 className='text-dark'>CLASS IV - Exploring crypto wallets: features, categories, and security measures</h6>
                                        {/* <ul>
                                            <li>1. Crypto wallets – secure your digital assets</li>
                                            <li>2. Characteristics of a cryptocurrency wallet</li>
                                            <li>3. Classifications of cryptocurrency wallets</li>
                                            <li>4. What is a secret phrase, and how can it be securely safeguarded?</li>
                                            <li>5. Variations between centralized and decentralized wallet.</li>
                                            <li>6. Establish an account and oversee your wallet.</li>
                                        </ul> */}
                                        <h6 className='text-dark'>CLASS V -  Everything you need to know about cryptocurrency exchanges </h6>
                                        {/* <ul>
                                            <li>  1. Cryptocurrency exchanges explained.      </li>
                                            <li> 2. Prominent global exchanges in the cryptocurrency market    </li>
                                            <li>  3. Varieties of cryptocurrency exchanges worldwide   </li>
                                            <li>4. Breaking down the functionality of crypto exchanges</li>


                                        </ul> */}
                                        <h6 className='text-dark'>CLASS VI - Crypto insights – analysis, investment & beyond   </h6>
                                        {/* <ul>
                                            <li> 1. Fundamentals of analysis crypto credibility      </li>
                                            <li> 2. How to Analyze good crypto – analyzation techniques  </li>
                                            <li>  3.  Tax Compliance Rules for Crypto Exchanges in India     </li>
                                            <li>4. The Many Faces of Cryptocurrency Fraud and Scams     </li>
                                            <li>5. Maximizing Safety in Cryptocurrency Investments: Tips   </li>
                                            <li>6. Global Perspectives on Cryptocurrency Regulation    </li>
                                            <li> 7. Cryptocurrency Regulation in India: Government Policies      </li>
                                            <li>8. Invasion of blockchain: catalysing transformation and reshaping society    </li>
                                            <li> 9. Job Growth Potential in the Blockchain Field.  </li>
                                            <li>10. Cryptocurrency's Utility Features  </li>
                                            <li> 11. Firms Welcoming Cryptocurrency Transactions    </li>
                                            <li>12. Blockchain Applications Across Various Sectors </li>
                                        </ul> */}

                                    </div>
                                </Tab>
                                <Tab eventKey="course guide" title="Course Guide" >
                                    <div className='course_cont'>
                                        <h2>Course Enrollment</h2>
                                        <ul>
                                            <li>
                                                Register on the UltraPro exchange and create an exchange account.
                                            </li>
                                            <li>
                                                Deposit fiat currencies into your UltraPro exchange account and convert them to UPRO coins.
                                            </li>
                                            <li>
                                                Navigate to the course enrollment section on the Probinar website.
                                            </li>
                                            <li>
                                                Select the desired course and proceed to purchase the ticket.
                                            </li>
                                            <li>
                                                Complete the enrollment form, including any required employee codes if applicable.
                                            </li>
                                            <li>
                                                A QR code containing the wallet address for payment will be generated.
                                            </li>
                                            <li>
                                                Use your UPRO wallet to scan the QR code and complete the payment to finalize your enrollment.
                                            </li>
                                        </ul>

                                        <h2>
                                            Eligibility Criteria:
                                        </h2>
                                        <ul>
                                            <li>
                                                Individuals who are 18 years of age or older are eligible to attend this course.
                                            </li>
                                        </ul>

                                        <h2>Course Duration:</h2>
                                        <ul>
                                            <li>
                                                The course consists of 6 live sessions accompanied by 6 assessments.
                                            </li>
                                            <li>
                                                To receive certification in NFT, students must attend all the live sessions and assessments.
                                            </li>
                                        </ul>

                                        {/* <strong>Register on the UltraPro exchange and create an exchange account.</strong>
                                        <strong> 2. Open to Everyone: </strong>
                                        <strong> 3. Risk Awareness:</strong>
                                        <strong> 4. Building Awareness:</strong>
                                        <strong> 5. Target Audience Consideration:</strong>
                                        <strong> 6. Success Metric:</strong>
                                        <strong> 7. Interactive Learning:</strong> */}

                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Course