

// import React, { useState } from 'react'
// import { Input } from 'reactstrap'
// import { ApiServices } from '../ApiServices'
// import axios from 'axios'
// import toast from 'react-hot-toast'
// import Loader from './Spinner'

// function Download() {
//     const [inputValue, setInputValue] = useState('')
//     const [loading, setLoading] = useState(false)

//     const handleChange = (e) => {
//         const value = e.target.value
//         setInputValue(value)
//     }

//     const handleDownload = async (downloadType) => {
//         try {
//             setLoading(true)

//             const queryString = new URLSearchParams(inputValue).toString();
//             const apiUrl = downloadType === 'complete' 
//                 ? ApiServices.SUCCESS_DOWNLOAD 
//                 : ApiServices.PENDING_DOWNLOAD;

//             const response = await axios.post(`${apiUrl}?${queryString}`, {
//                 // Include any data you need to send with the POST request here
//             }, {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 responseType: 'blob'
//             });

//             setLoading(false)

//             if (response.data) {
//                 const blob = response.data;
//                 const url = window.URL.createObjectURL(new Blob([blob]));
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', downloadType === 'complete' 
//                     ? 'Probinar Payment Completed Users.xlsx' 
//                     : 'Probinar Payment Pending Users.xlsx');
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//                 window.URL.revokeObjectURL(url); // Revoke the object URL after the download
//             } else {
//                 toast.error(response.data.message)
//             }
//         } catch (err) {
//             setLoading(false)
//             console.error('Error downloading the file', err);
//         }
//     };

//     return (
//         <div>
//             <div className="mb-3">
//                 <label htmlFor="customername-field" className="form-label">Code</label>
//                 <Input
//                     autoComplete='off'
//                     type="text"
//                     id="customername-field"
//                     className="form-control"
//                     placeholder="Enter"
//                     name="inputValue"
//                     onChange={handleChange}
//                     value={inputValue || ""}
//                 />
//                 <button 
//                     onClick={() => handleDownload('complete')} 
//                     className='bg-info rounded px-4 py-1' 
//                     disabled={loading}
//                 >
//                     {loading ? <Loader /> : "Complete"}
//                 </button>
//                 <button 
//                     onClick={() => handleDownload('pending')} 
//                     className='bg-info rounded px-4 py-1' 
//                     disabled={loading}
//                 >
//                     {loading ? <Loader /> : "Pending"}
//                 </button>
//             </div>
//         </div>
//     )
// }

// export default Download
import React, { useEffect, useState } from 'react';
import { Card, Input, InputGroup } from 'reactstrap';
import { ApiServices } from '../ApiServices';
import axios from 'axios';
import toast from 'react-hot-toast';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

function Download() {
    const [formdata,setFormData] = useState({});
    const [rangeUsers,setRangeUsers] = useState('');


    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const today = new Date();

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
useEffect(()=>{
    handlePost()
},[])
    const handlePost = async (data) => {
        setLoading(true)
       
        try {
            const response = await axios.post(ApiServices.TODAY_COUNT)
            
           

            if (response.status) {
                setFormData(response.data)
                console.log(response.data,"response.data");
                
                setLoading(false)
               

            }
            else {
          
                setLoading(false)
             
                // validation.resetForm();
            }

        } catch (error) {
        
            setLoading(false)


        }




    }






    const handleDateRange = async (start,end) => {
        setLoading(true)
        const postForm = {};


            if (start) {
                postForm.startDate = start;
            }
            if (end) {
                postForm.endDate = end;
            }
            const queryString = new URLSearchParams(postForm).toString();
        try {
            const response = await axios.post(`${ApiServices.DATE_COUNT}?${queryString}`)
            
           

            if (response.status) {
                if(response.data.clientsCount===0){
                    setRangeUsers("No Users")

                }else{
                    setRangeUsers(response.data.clientsCount)
                }
                console.log(response.data,"response.data");
                
                setLoading(false)
               

            }
            else {
          
                setLoading(false)
             
                // validation.resetForm();
            }

        } catch (error) {
        
            setLoading(false)


        }




    }


    const handleDateChange = ([start, end]) => {
        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;

        setDateRange([start, end]);

        if (formattedStart && formattedEnd) {
            console.log(formattedStart, "START");
            console.log(formattedEnd, "END");
            handleDateRange(formattedStart,formattedEnd)
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
        }
        if (!formattedStart && !formattedEnd) {
            setDownloadEndDate({})
        }
    };

    const handleDownload = async (downloadType) => {
        console.log(downloadType,"DOWNLOAD");
        
        setLoading(true);
        try {
            const postForm = {};


            if (downloadStartDate.startDate) {
                postForm.startDate = downloadStartDate.startDate;
            }
            if (downloadStartDate.endDate) {
                postForm.endDate = downloadStartDate.endDate;
            }
            const queryString = new URLSearchParams(inputValue).toString();

          

            const apiUrl = downloadType === 'complete' 
                ? ApiServices.SUCCESS_DOWNLOAD 
                : ApiServices.PENDING_DOWNLOAD;
                const fullUrl = `${apiUrl}?${queryString}`;
            const response = await axios.post(fullUrl,postForm, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                const blob = response.data;
                console.log(response.data,"ytutyutyutyuytuyt+B");
                
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', downloadType === 'complete' 
                    ? 'Probinar Payment Completed Users.xlsx' 
                    : 'Probinar Payment Pending Users.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url); // Revoke the object URL after the download
            } else {
                toast.error(response.data.message)
            }
            // if (response.data) {
            //     const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            //     const url = window.URL.createObjectURL(blob);
            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute('download', downloadType === 'complete' 
            //         ? 'Probinar Payment Completed Users.xlsx' 
            //         : 'Probinar Payment Pending Users.xlsx');
            //     document.body.appendChild(link);
            //     link.click();
            //     link.remove();
            //     window.URL.revokeObjectURL(url);
            //     toast.success('File downloaded successfully');
            // } else {
            //     toast.error('Failed to download the file');
            // }
        } catch (err) {
            console.error('Error downloading the file', err);
            toast.error('Error downloading the file');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center' style={{height:"100vh"}}>
            <Card className='d-flex justify-content-around align-items-center p-5 modal-lg' style={{width:'60%'}}>
            <div className="mb-3" style={{width:'80%'}}>
            <InputGroup>
                <Flatpickr
                    className="form-control d-block"
                    placeholder="Select Start to End Date"
                    options={{
                        mode: 'range',
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        maxDate: today,
                    }}
                    value={dateRange}
                    onChange={handleDateChange}
                />
            </InputGroup>
            </div>
            {rangeUsers ?(
                <p style={{color:'black'}}>Rate Range Users : {rangeUsers}</p>
            ):('')}
                
            <div className="mb-3" style={{width:'80%'}}>
                <Input
                    autoComplete='off'
                    type="text"
                    id="code-field"
                    className="form-control"
                    placeholder="Enter code"
                    name="inputValue"
                    onChange={handleChange}
                    value={inputValue || ""}
                />
                </div>


                <div className="" style={{width:'80%'}}>
               
                        <div class="alert alert-secondary d-flex align-items-center" role="alert">
                         Total Users : {formdata.overallTotalCount}
                        </div>
                        <div class="alert alert-primary d-flex align-items-center" role="alert">
                            Payment Success User:{formdata.completedCount}
                        </div>
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                        Payment Pending User:{formdata.pendingCount}
                        </div>
                        <div class="alert alert-secondary d-flex align-items-center" role="alert">
                         Today Total Users : {formdata.todayTotalCount}
                        </div>
                </div>
                <div className="d-flex flex-column">
                <button 
                    onClick={() => handleDownload('complete')} 
                    className='bg-info rounded px-4 py-1 my-3' 
                    disabled={loading}
                >
                    {loading ? "Loading..." : "Complete"}
                </button>
                <button 
                    onClick={() => handleDownload('pending')} 
                    className='bg-info rounded px-4 py-1' 
                    disabled={loading}
                >
                    {loading ? "Loading..." : "Pending"}
                </button>
                </div>
           
            
            </Card>
        </div>
    );
}

export default Download;
