import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <ToastContainer
            position="top-center"
            autoClose={3000} // Adjust as needed
            hideProgressBar={false} // Change to true if you don't want a progress bar
            newestOnTop={false} // Set to true to show newest notification on top
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    <App /> 
  </BrowserRouter>

);

reportWebVitals();
