import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useLocation } from 'react-router-dom';



const Teamb = () => {


  let location = useLocation();

  const data = location.state.data;


  return (
    <>
      <Navbar/>
      <div className='main-content'>
        <div className='team_con'>
          <div className='container'>
            <div className='team_c'>
              <div className='team_im'>
                <img src={data.profile} alt="member" draggable="false"></img>

              </div>
              <div className='tea_c'>
                <strong>OUR TEAM</strong>
                <h2>{data.name}</h2>
                <p>{data.designation}</p>
                <p>
                  {data.about}
                </p>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Teamb