import React from "react";
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import Footer from '../Footer';
// <Navbar />
// <Footer /> 
const Privacypolicy = () => {
  return (
    <>
      <Helmet>
        <title> Probinar - Privacy policy</title>
        <meta
          name="description"
          content="View Probinar's Privacy Policy: Understand how we prioritize data protection and transparency in all interactions."
        ></meta>
        <meta
          name="keywords"
          content="  Privacy policy, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners
"
        ></meta>
        <link rel="canonical" href="https://www.probinar.in-privacy-policy" />
        <meta
          property="og:url"
          content="https://www.probinar.in-privacy-policy"
        />
        <meta property="og:title" content=" Probinar - Privacy policy" ></meta>


        <meta property="og:image" content="https://probinar-privacypolicy.s3.amazonaws.com/Privacy+Policy.jpg" ></meta>
        <meta property="og:image:alt" content="  Probinar - Privacy policy" ></meta>

        <meta property="og:site_name" content="  Probinar - Privacy policy" ></meta>
        <meta property="og:description" content=" View Probinar's Privacy Policy: Understand how we prioritize data protection and transparency in all interactions."></meta>
        <meta property="twitter:domain" content="https://www.probinar.in/terms-and-conditions"></meta>


        <meta name="twitter:title" content=" Probinar - Privacy policy" ></meta>
        <meta name="twitter:description" content=" View Probinar's Privacy Policy: Understand how we prioritize data protection and transparency in all interactions." ></meta>
        <meta name="twitter:image" content="https://probinar-privacypolicy.s3.amazonaws.com/Privacy+Policy.jpg" ></meta>
      </Helmet>
      <Navbar />
        <div className="main-content">
          <div className="bred">
            <div className="container">
              <div className="con">
                <h1>Privacy policy</h1>
                <ul>
                  <li>Home</li>
                  <li>Privacypolicy</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="privcypoli">
            <div class="container rounded shadow col-10 border border-1">
              <div class="p-5">
                <div class="pb-3">
                  <h1 fw-bold class="pb-4">
                    <b>Probinar Privacy Policy</b>
                  </h1>
                  <h2 fw-semibold class="pb-4">
                    Last Updated
                  </h2>
                  <p>
                    Probinar cares deeply about the privacy and security of your
                    information. This Privacy Policy outlines how Probinar
                    collects, uses, and shares your Personal Information when you
                    interact with our website or utilize our services. By
                    accessing our website or using our services, you agree to the
                    terms outlined in this Privacy Policy.
                  </p>
                </div>
                <div class="pb-3">
                  <h2 fw-semibold>Information We Collect</h2>
                  <p>
                    When you interact with Probinar, we may collect various types
                    of information, including Personal Information. This may
                    include:
                  </p>
                  <div>
                    <ul>
                      <li>
                        Information you provide when creating an account, such as
                        your name, email address, and country of residence.
                      </li>
                      <li>
                        Information is collected automatically when you browse our
                        website, as detailed in our Cookie Policy.
                      </li>
                      <li>
                        Information is provided when enrolling in our blockchain
                        courses or programs.
                      </li>
                      <li>
                        Information collected during your participation in our
                        courses, including activity and performance data.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="pb-3">
                  <h2 fw-semibold>How We Use Your Personal Information</h2>
                  <h3 fw-bold>
                    Probinar uses your Personal Information for the following
                    purposes:
                  </h3>
                  <div>
                    <ul>
                      <li>
                        To operate and improve our website and services, including
                        personalizing your learning experience.
                      </li>
                      <li>
                        To create, administer, provide, and teach our blockchain
                        courses.
                      </li>
                      <li>
                        To offer and enhance our products and services, including
                        facilitating course enrollment and providing certificates.
                      </li>
                      <li>
                        To maintain the security and performance of our website
                        and services.
                      </li>
                      <li>
                        To communicate with you, including responding to inquiries
                        and providing updates about our platform.
                      </li>
                      <li>
                        To promote our courses and programs, as permitted by law.
                      </li>
                      <li>
                        To support scientific research in fields such as cognitive
                        science and education.
                      </li>
                      <li>
                        To track website usage and evaluate course performance.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="pb-3">
                  <h2 fw-semibold>How We Share Your Personal Information</h2>
                  <h3 fw-bold>
                    Probinar may share your Personal Information in the following
                    circumstances:
                  </h3>
                  <div>
                    <ul>
                      <li>
                        With our service providers, contractors, and other third
                        parties who assist in providing our services.
                      </li>
                      <li>
                        With other learners in our courses, to facilitate
                        interactive learning experiences.
                      </li>
                      <li>With affiliated entities of Probinar.</li>
                      <li>
                        For legal or safety reasons, including compliance with
                        applicable laws and regulations.
                      </li>
                      <li>
                        In connection with a sale or transfer of our business or
                        assets.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="pb-3">
                  <h2 class="fw-semibold">Storage and Securit</h2>
                  <p>
                    Probinar stores Personal Information on its servers and may
                    also utilize third party service providers. We implement
                    appropriate security measures to protect your information, but
                    we cannot guarantee absolute security. In the event of a
                    security breach involving your Personal Information, we will
                    notify you as required by law.
                  </p>
                </div>
                <div class="pb-3">
                  <h2 class="fw-semibold">Privacy Policy Updates</h2>
                  <p>
                    We may update this Privacy Policy periodically. Any material
                    changes will be communicated via our website or directly to
                    you. Your continued use of our services after such changes
                    constitutes acceptance of the updated Privacy Policy.
                  </p>
                </div>
                <div class="pb-3">
                  <h2 class="fw-semibold">Contact Information</h2>
                  <p>
                    If you have any questions or concerns regarding your privacy
                    or Personal Information, or if you wish to access, correct, or
                    delete your Personal Information, please contact us at:
                  </p>
                  <p>Contact info: info@probinar.in</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />

    </>
  );
};

export default Privacypolicy;
