import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import prologo from '../assets/logo.svg'
import '../App.css'
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';

function CollapsibleExample() {
  const [menu, setMenu] = useState("home");

  return (
    <div className='p-4 pb-0 headers'>
      {['lg'].map((expand) => (
        <Navbar collapseOnSelect expand="lg" className="bg-light heds">
          <Container>
            <Navbar.Brand href="/"><img src={prologo} alt="Probinar logo" draggable="false"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='hmenu'>

                <Nav className="me-auto col-md-8 col-12 d-flex justify-content-center ">
                  <div className='d-flex col-8 justify-content-around text-dark hed_menu '>

                    <Nav.Link href="#" ><Link to="/hero">Home</Link></Nav.Link>
                    {/* <Nav.Link href="#"><Link onClick={() => { setMenu("course")}} to="/course" className={menu==='course' ?'active':''}>Courses</Link></Nav.Link> */}
                    <Nav.Link href="#"><Link to="/about-us" >About us</Link></Nav.Link>
                    <Nav.Link href="#"><Link to="/contact-us" >Contact</Link></Nav.Link>
                    <Nav.Link href="#"><Link to="/blog">Blog</Link></Nav.Link>
                    <Nav.Link href="#"><Link to="/teams">Teams</Link></Nav.Link>
                   
                  </div>
                </Nav>



                <Nav className='col-md-4 col-6 d-flex justify-content-around btlog'>
                  {/* <button className='btn-orange-outline px-4 py-1 ' ><b>Sign In</b></button> */}
                  <a href='https://hub.probinar.in/#/login' className='btn-orange-outline px-4 py-1 text-decoration-none'><b>Sign In</b></a>
                  <a href='https://hub.probinar.in/#/signup' className='btn-orange rounded-2 px-4 py-1 text-decoration-none'><b>Sign Up</b></a>
                  {/* <button className='btn-orange rounded px-4 py-1'><b>Sign Up</b></button> */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>







          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default CollapsibleExample;