import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import Footer from '../Footer'
import axios from 'axios'
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";



const Contact = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const navigate = useNavigate();


  const handleChange = (e) => {

    const {name, value} = e.target;

    setFormData({ ...formData, [name]: value });

  }

  const handleSubmit = async () => {

    const result = await axios.post("https://backend.probinar.in/users/conactInfo", formData);

    toast.success(result.data.message);
    
    navigate("/");
    
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: ""
    })


  }

  return (
    <>
      <Helmet>
        <title>Contact us</title>
        <meta name="description" content=" Reach out easily with our Contact Us page. Get in touch for inquiries, support, or collaborations. We're here to assist you"></meta>
        <meta name="keywords" content=" Contact us, blockchain developer courses, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners, Journey into blockchain education, blockchain education"></meta>
        <link rel="canonical" href="https://www.probinar.in/contact-us" />
        <meta property="og:url" content="https://www.probinar.in/contact-us" />
        <meta property="og:title" content="Contact us" ></meta>


        <meta property="og:image" content="https://probinar-contact-us.s3.amazonaws.com/Contact+Us.jpg" ></meta>
        <meta property="og:image:alt" content="Contact us" ></meta>

        <meta property="og:site_name" content=" Contact us" ></meta>
        <meta property="og:description" content="Reach out easily with our Contact Us page. Get in touch for inquiries, support, or collaborations. We're here to assist you"></meta>
        <meta property="twitter:domain" content="https://www.probinar.in/contact-us"></meta>


        <meta name="twitter:title" content="Contact us" ></meta>
        <meta name="twitter:description" content="Reach out easily with our Contact Us page. Get in touch for inquiries, support, or collaborations. We're here to assist you" ></meta>
        <meta name="twitter:image" content="https://probinar-contact-us.s3.amazonaws.com/Contact+Us.jpg" ></meta>
      </Helmet>
      <Navbar />
      <div className='main-content'>
        <div className='bred'>
          <div className='container'>
            <div className='con'>
              <h1>Contact Us</h1>
              <ul>
                <li>Home</li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='contact'>
          <div className="containercol-12 text-center py-5 ctst">
            <h2><b>We are available to assist you!</b></h2>
            <h2 className="pb-5"><b>Contact us if you have any questions. We are ready to assist.</b></h2>
            <div className="container contct  rounded-3 shadow col-xl-10 col-lg-8 col-11 p-5">
              <h4 className="fw-semibold pb-2">Leave us a message, and we'll get back to you.</h4>
              <h5 className="fw-lighter fs-6 pb-3">Our knowledgeable learning consultant will assist you in creating a professional path for yourself if you have any questions about admissions. </h5>

              <div className="d-flex justify-content-center ctfrm">
                <form>
                  <div className="mb-3 text-start">
                    <label for="Name" className="form-label fw-semibold">Name</label>
                    <input type="text" className="form-control" id="exampleInputname" name="name" value={formData.name} onChange={handleChange} ></input>
                  </div>
                  <div className="mb-3 text-start">
                    <label for="email" className="form-label fw-semibold">Email</label>
                    <input type="email" className="form-control " id="exampleInputemail" name="email" value={formData.email} onChange={handleChange}></input>
                  </div>
                  <div className="mb-3 text-start">
                    <label for="Phone Number" className="form-label fw-semibold">Phone Number</label>
                    <input type=" text" className="form-control " id="exampleInputPhoneNumber" name="phone" value={formData.phone} onChange={handleChange}></input>
                  </div>
                  <div className="mb-3 text-start pb-2">
                    <label for="email" className="form-label fw-semibold">Message</label>
                    <textarea name="message" className="form-control " id="" cols="35" rows="4" value={formData.message} onChange={handleChange}></textarea>
                  </div>
                  <button type="submit" className="orange-btn text-light rounded-3 border-0 px-5 py-2 w-100" onClick={handleSubmit} >Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contact