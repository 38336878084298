import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

import { useNavigate, Link } from "react-router-dom";


const Team = () => {

    const navigate = useNavigate();

    const data = [
        {
            name: "Krishnaveni",
            designation: "Operations Director",
            about: "As a leader in operational excellence, she ensures smooth operations by leading key projects, improving processes, and boosting efficiency. Focused on company growth, she streamlines work and enhances productivity, driving the organization toward success.",
            profile: "images/krishna2.png"
        },
        {
            name: "Vinoth Kumar",
            designation: "Admin Officer",
            about: "As an Admin Associate, he has successfully streamlined operations. His goal is to support administrative tasks, maintain office efficiency, and ensure smooth daily operations to contribute to the overall success of the organization. ",
            profile: "images/vinotha.png"
        },
        {
            name: "Nagaprasanth",
            designation: "Marketing Coordinator",
            about: "As a Marketing Coordinator, he has achieved success with his campaigns. His aim is to coordinate marketing efforts, engage with clients, and drive promotional activities to enhance brand awareness and effectively achieve marketing objectives.",
            profile: "images/pransatha.png"
        },
        {
            name: "Vishal",
            designation: "Admin Associate",
            about: "As an Admin Associate, he has successfully streamlined operations. His goal is to support administrative tasks, maintain office efficiency, and ensure smooth daily operations to contribute to the overall success of the organization. ",
            profile: "images/vishala.png"
        },
        {
            name: "Leon",
            designation: "CTO",
            about: "Leon, an experienced Chief Technology Officer (CTO), drives innovation and oversees the development of cutting-edge technology solutions, aligning them with business objectives to propel company growth and success. ",
            profile: "images/leona.png"
        },
        {
            name: "Chellasamy",
            designation: " Associate Manager",
            about: "As an Associate Manager, he has a track record of achieving team success. His aim is to support the growth of his team, drive projects forward, and foster collaboration to achieve departmental goals and contribute to the overall success of the company.",
            profile: "images/chella1a.png"
        },
        {
            name: "Madhu Sudhan",
            designation: "Corporate Trainer",
            about: "As a Corporate Trainer, Madhu Sudhan focuses on coaching our business development executives, emphasizing product training and soft skill development. His guidance ensures our team members excel in understanding our products and mastering essential interpersonal skills for success. ",
            profile: "images/madhu1a.png"
        },
        {
            name: "Ashwin",
            designation: "Media Manager",
            about: "As a media manager he drives brand growth by developing and implementing strategic media campaigns that enhance brand visibility and engagement, planning and execution of marketing campaigns across various channels, including digital platforms and traditional media. collaborate with creative teams to develop compelling content, analyze campaign performance to optimize strategies.",
            profile: "images/ashwina.png"
        },
        {
            name: "Manoj Pandian",
            designation: "Tech Team Lead",
            about: "As a Tech Team Lead, Manoj Pandian achieved team success. He leads tech innovations, mentor team members, and drive projects to meet company goals with excellence.",
            profile: "images/manoima.png"
        },
        {
            name: "Shridurga",
            designation: "R&D Analyst",
            about: "As an R&D Analyst, Shridurga specializes in conducting detailed analyses of competitors and presenting insights that inform strategic decision-making. She plays a pivotal role in shaping the company's strategies and development through research initiatives, providing actionable recommendations",
            profile: "images/shria.png"
        },
        {
            name: "Gobivignesh",
            designation: "Digital Marketing Executive",
            about: "As a Digital Marketing Executive, Gobivignesh architects and executes innovative marketing plans, adeptly handles various social media platforms, crafts engaging content tailored to audience preferences, and fine-tunes campaigns to boost brand visibility and interaction.",
            profile: "images/gopia.png"
        },
        {
            name: "Priyadharshini",
            designation: "Digital Marketing Executive",
            about: "As a Digital Marketing Executive, Priya formulates and implements effective marketing strategies, efficiently oversees multiple social media channels, creates engaging content tailored to target audiences, and optimizes campaigns to maximize brand visibility and engagement.",
            profile: "images/priyaa.png"
        },
        
        {
            name: "Harshan Krishna",
            designation: "Graphic Designer",
            about: "As a Graphic Designer, he has achieved captivating designs. His goal is to create stunning visuals, enhance brand identity, and drive creative excellence to elevate the company's marketing materials and communication strategies.",
            profile: "images/harshana.png"
        },
        {
            name: "Vellasamy",
            designation: "Public Relations Officer",
            about: "As a Public Relations Officer, he has successfully cultivated a positive brand image. His aim is to establish strong media relations, oversee communications, and boost brand reputation to improve public perception and align with organizational objectives.",
            profile: "images/vellaa.png"
        },
        {
            name: "Ram Prakash",
            designation: "Associate UI Developer",
            about: "As an Associate UI Developer, he's skilled at crafting user-friendly designs. He aims to build intuitive interfaces, enhance user experience, and create visually appealing and functional UI elements for our digital products.",
            profile: "images/rama.png"
        },
        {
            name: "Priya Dharshini",
            designation: "Junior DevOps Engineer",
            about: "As a Junior DevOps Engineer, Priyadharshini is great at making deployments smooth. She wants to help with infrastructure, automate things, and make software delivery better so our work goes smoothly and our products are more reliable.",
            profile: "images/priyaba.png"
        },
        {
            name: "Balamurugan",
            designation: "Development Consultant",
            about: "As a Development Consultant, he has achieved project success. His goal is to provide expert guidance, drive innovation, and deliver solutions to clients to optimize their development processes and achieve business objectives",
            profile: "images/balaa.png"
        },
        {
            name: "Balaji",
            designation: "Public Relations Officer",
            about: "our Public Relations Officer, expertly manages the company's public image and media communications, fostering strong relationships with key stakeholders. Manages public image, media relations, and stakeholder communications.",
            profile: "images/kinaa.png"
        },
        {
            name: "Thanga karthick",
            designation: "Chief Business Manager",
            about: "as Chief Business Manager, oversees strategic growth initiatives, drives business development, and ensures the achievement of key performance objectives. He plays a crucial role in shaping the company's market expansion and profitability. Leads strategic growth, business development, and market expansion.",
            profile: "images/goldb.png"
        },
     
    ]

    const handleProfile = (id) => {
        navigate('/teamb', { state: { data: data[id] } });
    };
    return (
        <>
            <Navbar />
            <div className='main-content'>
                {/* <div className='banc'>
                    <img src='images/banteam.jpg' alt="banner" draggable="false"></img>
                </div> */}
                <div className='team'>
                    <div className='container'>
                        <strong>Meet Our Team</strong>
                        <p>A diverse group of dedicated professionals driving innovation and success. Learn more about the people who make our company great and how we work together.</p>

                        <div className='team_member'>
                            <div className='mem'>
                                <img src='images/krishna1.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(0)}>Krishnaveni</strong>
                                <span>Operations Director</span>
                                <span className='in'>Linkedin</span>
                            </div>
                            <div className='mem' >
                                <img src='images/vinoth.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(1)}>Vinothkumar</strong>
                                <span>Admin Officer</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/vinoth-kumar-admin/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem'>
                                <img src='images/prasanth.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(2)}>Nagaprasanth</strong>
                                <span>Marketing Coordinator</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/nagaprasanth-nagarajan-01b2a9303/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem'>
                                <img src='images/vishal.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(3)}>Vishal</strong>
                                <span>Admin Associate</span>
                                <span className='in'>Linkedin</span>
                            </div>
                            <div className='mem' >
                                <img src='images/leon.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(4)}>Leon</strong>
                                <span>CTO</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/leon-j-b4031021/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem' >
                                <img src='images/chella1.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(5)}>Chellasamy</strong>
                                <span>Associate Manager</span>
                                <span className='in' target='_blank'>Linkedin</span>
                            </div>
                            <div className='mem'>
                                <img src='images/madhu.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(6)}>Madhu Sudhan</strong>
                                <span>Corporate Trainer</span>
                                <span className='in' target='_blank'>Linkedin</span>
                            </div>
                            <div className='mem' >
                                <img src='images/ashwin.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(7)}>Ashwin</strong>
                                <span>Media Manager</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/ashwin-m-95882820b/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem' >
                                <img src='images/iga.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(8)}>Manoj Pandian</strong>
                                <span>Tech Team Lead</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/manoj-pandian-aa693020a/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem'>
                                <img src='images/shri.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(9)}>Shridurga</strong>
                                <span>R&D Analyst</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/shridurga2001/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem' >
                                <img src='images/gopi.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(10)}>Gobivignesh</strong>
                                <span>Digital Marketing Executive</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/gobi-vignesh/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem' >
                                <img src='images/priya_rr.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(11)}>Priyadharshini</strong>
                                <span>Digital Marketing Executive</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/priyadharshini-rajasekar-b3b956251/" target='_blank'>Linkedin</Link></span>
                            </div>
                            {/* <div className='mem' >
                                <img src='images/joshuva.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(12)}>Joshua Jebaraj</strong>
                                <span>HR Head</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/joshua-jeba-raj-4439571b/" target='_blank'>Linkedin</Link></span>
                            </div> */}
                            <div className='mem' >
                                <img src='images/harshan.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(12)}>Harshan Krishna</strong>
                                <span>Graphic Designer</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/harshankrishna-k-v/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem' >
                                <img src='images/vella.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(13)}>Vellasamy</strong>
                                <span>Public Relations Officer</span>
                                <span className='in' target='_blank'>Linkedin</span>
                            </div>
                            <div className='mem'>
                                <img src='images/ram.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(14)}>Ram Prakash</strong>
                                <span>Associate UI Developer</span>
                                <span className='in'><Link to="https://www.linkedin.com/in/ramprakashmj/" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem' >
                                <img src='images/priya.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(15)}>Priya Dharshini</strong>
                                <span>Junior DevOps Engineer</span>
                                <span className='in'><Link to="https://in.linkedin.com/in/priya-dharshini-577955271?original_referer=https%3A%2F%2Fwww.google.com%2F" target='_blank'>Linkedin</Link></span>
                            </div>
                            <div className='mem'>
                                <img src='images/bala.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(16)}>Balamurugan</strong>
                                <span>Development Consultant</span>
                                <span className='in' target='_blank'>Linkedin</span>
                                </div>
                                <div className='mem'>
                                <img src='images/balaji.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(17)}>Balaji</strong>
                                <span>Public Relations Officer</span>
                                <span className='in' target='_blank'>Linkedin</span>
                            </div>
                            <div className='mem'>
                                <img src='images/gold.png' alt='memberone' draggable="false"></img>
                                <strong onClick={() => handleProfile(18)}>Thanga karthick</strong>
                                <span>Chief Business Manager</span>
                                <span className='in' target='_blank'>Linkedin</span>
                            </div>
                           </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Team