import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import Footer from '../Footer';
// <Navbar />
// <Footer /> 

const Blogs = () => {
  return (
    <>
      <Helmet>
        <title>Probinar - BLOG PAGE</title>
        <meta
          name="description"
          content="Explore insightful articles and updates on blockchain technology and more on Probinar's blog page. Stay informed and stay ahead"
        ></meta>
        <meta
          name="keywords"
          content="  Probinar blog, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners"></meta>
        <link rel="canonical" href="https://www.probinar.in/blog" />
        <meta
          property="og:url"
          content="https://www.probinar.in/blog"
        />

        <meta property="og:title" content=" Probinar - BLOG PAGE" ></meta>


        <meta property="og:image" content="https://probinar-blog.s3.amazonaws.com/Blog.jpg" ></meta>
        <meta property="og:image:alt" content="Probinar - BLOG PAGE" ></meta>

        <meta property="og:site_name" content=" Probinar - BLOG PAGE" ></meta>
        <meta property="og:description" content="Explore insightful articles and updates on blockchain technology and more on Probinar's blog page. Stay informed and stay ahead"></meta>
        <meta property="twitter:domain" content="https://www.probinar.in/blog"></meta>


        <meta name="twitter:title" content=" Probinar - BLOG PAGE" ></meta>
        <meta name="twitter:description" content="Explore insightful articles and updates on blockchain technology and more on Probinar's blog page. Stay informed and stay ahead" ></meta>
        <meta name="twitter:image" content="https://probinar-blog.s3.amazonaws.com/Blog.jpg" ></meta>


      </Helmet>
      <Navbar />
      <div className='main-content'>
        <div className='bred'>
          <div className='container'>
            <div className='con'>
              <h1>Blogs</h1>
              <ul>
                <li>Home</li>
                <li>Blogs</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='bloglr'>
          <div className='container'>
            <div className='blogc'>
              <div className='bloglft'>
                <h2>Probinar, where opportunities start!</h2>
                <p>We are a top platform for blockchain online education and training. Learn new things by examining some of our most valuable courses. </p>
              </div>
              <div className='blogrgt'>
                <img src="images/blogima.png" alt='Probinar-blog' draggable="false"></img>
              </div>
            </div>
          </div>
        </div>
        <div className='blog_cont'>
          <div className='container'>
            <div className='blg_c'>
              <h2>BLOGS</h2>
              <div className='blg_cards'>
                <div className='blgcar'>
                  <img src='images/bla.png' alt='Why Blockchain Education is Essential in Today  Job Market' draggable="false"></img>
                  <div className='blcon'>
                    <p>05 Jun, 2023</p>
                    <h3>Innovate Your Career: Why Blockchain Education is Essential in Today's Job Market</h3>
                    <a href='#'><Link to="/why-blockchain-education-is-essential">Learn More</Link></a>
                  </div>
                </div>
                <div className='blgcar'>
                  <img src='images/blb.png' alt='Journey into blockchain education' draggable="false"></img>
                  <div className='blcon'>
                    <p>05 Jun, 2023</p>
                    <h3>Empowering minds: Journey into blockchain education</h3>
                    <a href='#'><Link to="/journey-into-blockchain-education">Learn More</Link></a>
                  </div>
                </div>
                <div className='blgcar'>
                  <img src='images/blc.png' alt='How can blockchain certifications boost your career?' draggable="false"></img>
                  <div className='blcon'>
                    <p>05 Jun, 2023</p>
                    <h3>How can blockchain certifications boost your career?</h3>
                    <a href='#'><Link to="/how-blockchain-boost-your-career">Learn More</Link></a>
                  </div>
                </div>
                <div className='blgcar'>
                  <img src='images/bld.png' alt='Blockchain in Government' draggable="false"></img>
                  <div className='blcon'>
                    <p>05 Jun, 2023</p>
                    <h3>Blockchain in government: Enhancing transparency and Accountability.</h3>
                    <a href='#'><Link to="/blockchain-in-government">Learn More</Link></a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Blogs