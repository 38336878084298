import React from 'react'
import { Spinner } from 'reactstrap'
function Loader() {
    return (
        <>
            <Spinner size="sm">
                Loading...
            </Spinner>
            <span style={{ color: 'white' }}>
                {' '}Loading...
            </span>
        </>
    )
}

export default Loader