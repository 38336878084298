import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import Footer from '../Footer';
// <Navbar />
// <Footer /> 

const Termsconditions = () => {
  return (
    <>

      <Helmet>
        <title> Probinar - Terms and Conditions</title>
        <meta name="description" content=" Find clarity in Probinar's Terms and Conditions, outlining fair course access, transparent usage guidelines, and robust data protection."></meta>
        <meta name="keywords" content=" Terms and Conditions, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners"></meta>
        <link rel="canonical" href="https://www.probinar.in/terms-and-conditions" />
        <meta property="og:url" content="https://www.probinar.in/terms-and-conditions" />
        <meta property="og:title" content="Probinar - Terms and Conditions" ></meta>


        <meta property="og:image" content="https://probinar-termsandconditions.s3.amazonaws.com/Terms+and+Condition.jpg" ></meta>
        <meta property="og:image:alt" content=" Probinar - Terms and Conditions" ></meta>

        <meta property="og:site_name" content=" Probinar - Terms and Conditions" ></meta>
        <meta property="og:description" content=" Find clarity in Probinar's Terms and Conditions, outlining fair course access, transparent usage guidelines, and robust data protection."></meta>
        <meta property="twitter:domain" content="https://www.probinar.in/terms-and-conditions"></meta>


        <meta name="twitter:title" content="Probinar - Terms and Conditions" ></meta>
        <meta name="twitter:description" content=" Find clarity in Probinar's Terms and Conditions, outlining fair course access, transparent usage guidelines, and robust data protection." ></meta>
        <meta name="twitter:image" content="https://probinar-termsandconditions.s3.amazonaws.com/Terms+and+Condition.jpg" ></meta>
      </Helmet>
      <Navbar />

      <div className='main-content'>
        <div className='bred'>
          <div className='container'>
            <div className='con'>
              <h1>Terms And Conditions</h1>
              <ul>
                <li>Home</li>
                <li>Terms And Conditions</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='privcypoli'>
          <div class="container rounded shadow col-10 border border-1">
            <div class="p-5">
              <div class="pb-3">
                <h1 fw-bold class="pb-4" ><b>Probinar Terms and Conditions</b></h1>
                <h2 fw-semibold class="pb-4">Last Updated</h2>
                <p>
                  These terms and conditions govern your use of the Probinar website
                  ("probinar.in") and the purchase of our courses. In these Terms and
                  Conditions, "Probinar" is referred to as the "Company", "us," or
                  "we." "You" refers to a user or a paying customer. By accessing the
                  Website and purchasing our courses, you agree to the terms and
                  conditions set forth herein If you do not accept these terms, you
                  must not use – and are not authorized to use – all or any portion of
                  the Website and our courses.
                </p>
              </div>
              <div class="pb-3">
                <h2 fw-semibold>Acceptance of this Agreement</h2>
                <p>
                  By using this Website and purchasing our courses, you agree and
                  warrant that you have read, understood, and agreed to be bound by
                  these terms. Our privacy policy can be found at Privacy Policy
                </p>
              </div>
              <div class="pb-3">
                <h2 fw-semibold>Use of UPRO Coin for Course Purchase</h2>
                <p>
                  Probinar accepts only UPRO Coin as a method of payment for
                  purchasing our courses. If you encounter difficulties in purchasing
                  the course using UPRO Coin, our support team will assist you in
                  completing the transaction
                </p>
              </div>
              <div class="pb-3">
                <h2 fw-bold>Website Usage Guidelines</h2>
                <p>
                  By using our Website and accessing our courses, you agree to adhere
                  to the following guidelines:
                </p>
                <ul>
                  <li>
                    Do not engage in unlawful activities or violate these Terms and
                    Conditions.
                  </li>
                  <li>
                    Do not insult, abuse, harass, stalk, threaten, or infringe the
                    rights of others.
                  </li>
                  <li>
                    Do not publish, post, distribute, or disseminate any defamatory,
                    infringing, indecent, offensive, or unlawful material or
                    information.
                  </li>
                  <li>
                    Do not engage in activities that compromise the security or
                    integrity of our Website or courses.
                  </li>
                  <li>
                    Do not misuse or attempt to misuse our Website or courses for
                    unauthorized purposes.
                  </li>
                  <li>
                    Do not engage in spamming, advertising, or selling unauthorized
                    services.
                  </li>
                  <li>
                    Do not violate any legal, regulatory, or contractual obligations
                    while using our Website or courses.
                  </li>
                </ul>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Intellectual Property Rights</h2>
                <p>
                  While you are granted a limited right to access and use our Website
                  and courses, you acknowledge and agree that Probinar retains all
                  intellectual property rights in the Website, courses, and related
                  content. You may not reproduce, distribute, or modify our content
                  without prior written consent.
                </p>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Limitation of Liability</h2>
                <p>
                  You expressly agree that your use of our Website and courses is at
                  your sole risk. Probinar shall not be liable for any direct,
                  indirect, incidental, special, or consequential damages arising from
                  your use of or inability to use our Website and courses.
                </p>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Governing Law and Jurisdiction</h2>
                <p>
                  This Agreement shall be governed by and construed in accordance with
                  the laws of [Insert Jurisdiction]. Any disputes arising under this
                  Agreement shall be subject to the exclusive jurisdiction of the
                  courts of [Insert Jurisdiction].
                </p>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Amendment and Assignment</h2>
                <p>
                  Probinar reserves the right to amend or modify this Agreement
                  without prior notification. Your continued use of our Website and
                  courses following any changes constitutes acceptance of those
                  changes. You may not assign this Agreement without our prior written
                  consent.
                </p>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Entire Agreement</h2>
                <p>
                  This Agreement, along with the Privacy Policy and any additional
                  guidelines posted on the Website, constitutes the entire agreement
                  between you and Probinar regarding the use of our Website and
                  courses, superseding any prior agreements or understandings
                </p>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Contact Information</h2>
                <p>
                  If you have any concerns or queries regarding this Agreement, please contact our Grievance
                </p>
              </div>
              <div class="pb-3">
                <p>Officer: Leon Praveen Kumar</p>
                <p>Grievance Officer: Vinoth Kumar Narayanan</p>
                <p>Email: info@probinar.in</p>
                <p>Last Updated on: 17/4/2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Termsconditions