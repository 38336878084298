import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import Footer from '../Footer';
// <Navbar />
// <Footer /> 

const About = () => {
    return (
        <>
            <Helmet>
                <title> About Probinar</title>
                <meta name="description" content="Get informed with Probinar and our mission to make blockchain education inclusive. Explore our journey and values on our About page."></meta>
                <meta name="keywords" content="  blockchain course, blockchain full course, blockchain classes near me, best blockchain courses for beginners, About us."></meta>
                <link rel="canonical" href="https://www.probinar.in/about-us" />
                <meta property="og:url" content="https://www.probinar.in/about-us" ></meta>
                <meta property="og:title" content=" About Probinar" ></meta>


                <meta property="og:image" content="https://probinar-about-us.s3.amazonaws.com/About+Us.jpg" ></meta>
                <meta property="og:image:alt" content=" About Probinar" ></meta>

                <meta property="og:site_name" content=" About Probinar" ></meta>
                <meta property="og:description" content="Get informed with Probinar and our mission to make blockchain education inclusive. Explore our journey and values on our About page."></meta>
                <meta property="twitter:domain" content="https://www.probinar.in/about-us"></meta>


                <meta name="twitter:title" content=" About Probinar" ></meta>
                <meta name="twitter:description" content="Get informed with Probinar and our mission to make blockchain education inclusive. Explore our journey and values on our About page." ></meta>
                <meta name="twitter:image" content="https://probinar-about-us.s3.amazonaws.com/About+Us.jpg" ></meta>
            </Helmet>
            <Navbar />
                <div className='main-content'>
                    <div className='bred'>
                        <div className='container'>
                            <div className='con'>
                                <h1>About Us</h1>
                                <ul>
                                    <li>Home</li>
                                    <li>About us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='abt_c'>
                        <div className='container'>
                            <div className='abtcon'>
                                <p>Probinar is a part of Ultrapro Blockchain, where our mission is to make blockchain understandable for everyone. In a tech-filled world, Probinar acts as a guide, connecting people to the potential of blockchain. We're here to simplify, bridge gaps, and empower you with the transformative essence of blockchain  technology.</p>
                            </div>
                        </div>
                    </div>
                    <div className='abt_ltr'>
                        <div className='container'>
                            <div className='abtlr row'>
                                <div className='abt_lft col-md-6 '>
                                    <img src="images/missa.png" alt="Mission" draggable="false"></img>
                                </div>
                                <div className='abt_rgt col-md-6'>
                                    <h2>Our Mission</h2>
                                    <p>At Probinar, our goal is to help everyone understand blockchain technology. We believe that learning about this amazing technology is the first step to unlocking its power. Through teaching and involvement, we want to empower people from all backgrounds to join the blockchain movement</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='abt_ltr abt_rls'>
                        <div className='container'>
                            <div className='abtlr row'>
                                <div className='abt_lft col-md-6 '>
                                    <img src="images/missb.png" alt=" Ultrapro Blockchain" draggable="false"></img>
                                </div>
                                <div className='abt_rgt col-md-6'>
                                    <h2>About Ultrapro Blockchain</h2>
                                    <p>Ultrapro Blockchain is a tech powerhouse, leading the pack in revolutionary blockchain solutions. Our innovative tech has reshaped industries. Probinar, a part of Ultrapro, extends this vision, aiming to make blockchain accessible to all. It's like bringing the tech magic to everyone, ensuring that the power and potential of blockchain are understood by all.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='abt_ltr abt_thi'>
                        <div className='container'>
                            <div className='abtlr row'>
                                <div className='abt_lft col-md-6 '>
                                    <img src="images/missc.png" alt="Why Probinar" draggable="false"></img>
                                </div>
                                <div className='abt_rgt col-md-6'>
                                    <h2>Why Probinar</h2>
                                    <p>At Probinar, our goal is to help everyone understand blockchain technology. We believe that learning about this amazing technology is the first step to unlocking its power. Through teaching and involvement, we want to empower people from all backgrounds to join the blockchain movement</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='abt_cards'>
                        <div className='container'>
                            <div className='abtcrd_c'>
                                <h2>The Probinar Promise</h2>
                                <div className='abtcrd'>
                                    <div className='ab_cr'>
                                        <div className='ab_crsl'>
                                            <div className='img'>
                                                <img src='images/cbooka.png' alt='SimpleLearning' draggable="false"></img>
                                            </div>
                                            <strong>SimpleLearning</strong>
                                        </div>
                                        <p>Probinar is here to teach blockchain easily. No matter if you're new or an expert, our lessons work for everyone.</p>

                                    </div>
                                    <div className='ab_cr'>
                                        <div className='ab_crsl'>
                                            <div className='img'>
                                                <img src='images/cbookb.png' alt='Exciting Programs' draggable="false"></img>
                                            </div>
                                            <strong>Exciting Programs</strong>

                                        </div>
                                        <p> Learning about blockchain is fun with us! We have workshops, webinars, and cool activities that make understanding blockchain interesting.</p>

                                    </div>
                                    <div className='ab_cr'>
                                        <div className='ab_crsl'>
                                            <div className='img'>
                                                <img src='images/cbookc.png' alt='Friendly Community' draggable="false"></img>
                                            </div>
                                            <strong> Friendly Community</strong>
                                        </div>
                                        <p>Probinar is not just a place; it's a group of people. Come, learn, and connect with others who are curious about blockchain. Let's chat and explore together!</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='abt_ltr  abtlas '>
                        <div className='container'>
                            <div className='abtlr row'>
                                <div className='abt_lft col-md-5 '>
                                    <img src="images/rock.png" alt=" Blockchain Journey " draggable="false"></img>
                                </div>
                                <div className='abt_rgt col-md-6'>
                                    <h2>Your Blockchain Journey Starts Here</h2>
                                    <p>Step into the world of blockchain with Probinar as your guide! Whether you're new to this tech realm or already familiar, we're the go-to place for unraveling the mysteries of blockchain. Join our community, where blockchain isn't just a tech trend but a tool for everyone to understand and leverage. Let's embark on this enlightening journey together!</p>
                                </div>
                                <p>Ready to Explore? Join Probinar Today and Embrace the Blockchain Revolution!</p>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default About