export const ApiServices={
    PAYMENTFORM:'https://backend.probinar.in/clients/create',
    UPDATE_ACCOUNT:'https://backend.probinar.in/payment/createOrder',
    SUCCESS_DOWNLOAD:'https://backend.probinar.in/payment/success',
    PENDING_DOWNLOAD:'https://backend.probinar.in/payment/pending',
    TODAY_COUNT:'https://backend.probinar.in/payment/todayClientsCount',
    DATE_COUNT:'https://backend.probinar.in/payment/clientsCountByDateRange',



    


    
}
export const KEY='rzp_live_kPFFIx3gU0n92X'

// rzp_live_kPFFIx3gU0n92X
// rzp_test_HJG5Rtuy8Xh2NB