import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'


const ShippingDelivery = () => {
  return (
    <>
     <Navbar />
      <div className="main-content">
        <div className="bred">
          <div className="container">
            <div className="con">
              <h1>Shipping & Delivery Policy</h1>
              <ul>
                <li>Home</li>
                <li>Shipping & Delivery Policy</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="refund-content">
          <div className=" container">
            <div className="refund">
                     <h1>Shipping & Delivery Policy </h1>
                     <p>Last updated on Jun 25th 2024</p>

<p>Shipping is not applicable for business.</p> 

                     <p>Disclaimer: The above content is created at ULTRAPRO TRADING SERVICES PRIVATE LIMITED's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.
                     Shipping & Delivery Policy</p>
                    
                </div>
             </div>
        </div>
        </div>
        <Footer/>

    
    </>
  )
}

export default ShippingDelivery