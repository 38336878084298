import React from "react";
import Navbar from '../Navbar'
import Footer from '../Footer';
const RefundCancell = () => {
  return (
    <>
      <Navbar />
      <div className="main-content">
        <div className="bred">
          <div className="container">
            <div className="con">
              <h1>Refund and Cancellation Policy</h1>
              <ul>
                <li>Home</li>
                <li>Refund and Cancellation Policy</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="refund-content">
          <div className=" container">
            <div className="refund">
              <h1>1. Cancellation Policy</h1>
                 <span>Last updated on 15-06-2024 19:52:02</span>
              <h2>1.1. Pre-Course Cancellation</h2>

              <p>
                Participants may cancel their enrollment up to 7 days before the
                course start date to receive a full refund. Cancellations made
                within 7 days of the course start date are not eligible for a
                refund.
              </p>

              <h2>1.2. Post-Course Start Cancellation</h2>

              <p>
                Once the course has commenced, no refunds will be issued. In
                case of extenuating circumstances, participants may request a
                deferment to a future course date. Such requests will be
                considered on a case-by-case basis and are subject to
                availability.
              </p>

              <h1>2.Refund Policy</h1>

              <h2>2.1. Full Refund Eligibility</h2>

              <p>
                To qualify for a full refund, cancellations must be made at
                least 7 days before the course start date. Refunds will be
                processed within 14 business days of the cancellation request.
              </p>

              <h2>2.2. Partial Refund Eligibility</h2>

              <p>
                Partial refunds are not offered. Participants are encouraged to
                transfer their enrollment to a future course if they are unable
                to attend the current session.
              </p>

              <h2>2.3. No Refunds</h2>

              <p>
                No refunds will be given for cancellations made within 7 days of
                the course start date or after the course has started.
              </p>
              <h3>Course Cancellation by ProBinar</h3>
              <p>
                If ProBinar cancels a course due to unforeseen circumstances,
                participants will be offered a full refund or the option to
                enroll in a future course at no additional cost. ProBinar
                reserves the right to reschedule or cancel courses with prior
                notice to participants.{" "}
              </p>

              <h4>How to Request a Refund or Cancellation</h4>
              <p>
                To request a cancellation or refund, please contact our support
                team at [support@probinar.in] with your order details and reason
                for cancellation. Refund requests will be processed within 14
                business days of receiving the request.
              </p>

              <h5>Contact Information</h5>
              <p>
                For any questions regarding this policy, please contact us at:
                Email: [support@probinar.in] By enrolling in the ProBinar
                course, you agree to the terms of this refund and cancellation
                policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default RefundCancell;
