import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect, useState } from 'react';
import onlineCourse from '../assets/Onlinecourse.svg';
import welcome from '../assets/welcomeImage.svg'
import achieve from '../assets/achieve.svg'
import '../App.css'
import card1 from '../assets/card1.svg'
import card2 from '../assets/card2.svg'
import card3 from '../assets/card3.svg'
import understanding from '../assets/understanding.svg'
import work from '../assets/work.svg'
import learning from '../assets/learning.svg'

import beginer from '../assets/beginner.svg'
import intermediate from '../assets/intermediate.svg'
import expert from '../assets/expert.svg'

import rightarrow from '../assets/right-arrow.svg'

import bottomImage from '../assets/bottom.svg'
import Navbar from './Navbar'
import Footer from './Footer';

import insights from '../assets/Insights.svg'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';

import students1 from '../assets/std1.png'
import students2 from '../assets/std2.png'
import students3 from '../assets/std3.png'
import students4 from '../assets/std4.png'
import students5 from '../assets/std5.png'
import students6 from '../assets/std6.png'

import axios from 'axios';


const Hero = () => {



    const [count, setCount] = useState(1000);

    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // function formatNumber(number) {
    //     if (number >= 1000000) {
    //         const millions = Math.floor(number / 1000000);
    //         const remainder = Math.floor((number % 1000000) / 1000);
    //         const formattedNumber = `${millions}M${remainder > 0 ? remainder : ''}`;
    //         return formattedNumber;
    //     } else if (number >= 1000) {
    //         const thousands = Math.floor(number / 1000);
    //         const remainder = number % 1000;
    //         const formattedNumber = `${thousands}K${remainder > 0 ? remainder : ''}`;
    //         return formattedNumber;
    //     }
    //     return number.toString();
    // }


    useEffect(() => {
        const cur_date = getCurrentDate();

        console.log("cur_date", cur_date);

        axios
            .post(`https://backend.probinar.in/users/userscount`, { cur_date: cur_date })
            .then((response) => {
                console.log("Response from getUser API:", response.data.userCount);
                const count = 1100 + response.data.userCount



                setCount(count);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [])

    const handleScroll = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Helmet>
                <title>Blockchain course online | Probinar</title>
                <meta name="description" content="Level up your skills with Probinar's online courses in blockchain technology. Start your live Online classes with NFT certification"></meta>
                <meta name="keywords" content=" Blockchain course online, online Blockchain course, blockchain development course, blockchain course for beginners,  blockchain developer courses, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners"></meta>
                <link rel="canonical" href="https://www.probinar.in" />
                <meta property="og:url" content=" https://www.probinar.in" />
                <meta name="twitter:title" content="Blockchain course online" />
                <meta name="twitter:description" content="Level up your skills with Probinar's online courses in blockchain technology. Start your live Online classes with NFT certification" />
                <meta name="twitter:image" content="https://probinar.s3.amazonaws.com/probinar.jpg" />
                <meta property="og:site_name" content="Blockchain course online" />
                <meta property="og:description" content=" Level up your skills with Probinar's online courses in blockchain technology. Start your live Online classes with NFT certification" />
                <meta property="twitter:domain" content="https://www.probinar.in"></meta>
                <meta property="og:image" content="https://probinar.s3.amazonaws.com/probinar.jpg" />
                <meta property="og:image:alt" content="Blockchain course online" />
            </Helmet>

            <Navbar />
            <div className='p-3 p-lg-5 hbf'>
                <div className='container d-lg-flex justify-content-center align-items-center'>
                    <div className='col-lg-6 col-md-10'>
                        <div className='text-center'>
                            <h1 className='homeheading text-start py-4'><b>Accelerate Success with <span className='d-block py-3 text-dark'>Our Livestream</span>  Blockchain Courses</b></h1>
                            <p className=' text-start py-4 text-dark'>
                                We're here for every way of your career growth by offering live stream online classes,
                                ensuring every student feels valued and supported in their learning journey.
                            </p>
                        </div>
                        <div className='banbt col-lg-10 col-md-6 col-sm-7 d-flex justify-content-between py-3'>
                            {/* <Button className='btn btn-light btn-outline-danger px-4 '><b>Join us now</b></Button> */}
                            {/* <Button className='orange '><b>Contact US</b></Button> */}
                            <button className='btn-orange rounded px-4 py-1'><Link to="https://hub.probinar.in/#/signup" onClick={handleScroll}>Join us now </Link></button>
                            <button className='btn-orange-outline px-4 py-1 '><Link to="/contact-us" onClick={handleScroll}>Contact Us</Link></button>
                        </div>
                    </div>
                    <div className='col-lg-5 pt-sm-5'>
                        <div className='d-flex justify-content-center'>
                            <img src={onlineCourse} alt='probinar' className='Blockchain course online' draggable="false" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 col-lg-10 hbs'>
                <div className='p-3 p-lg-5'>
                    <div className='container '>
                        <div className='verticalLine py-5'>
                            <h2><b>COURSES CATEGORIES</b></h2>
                            <h4 className='orange-text bcr'><b>Explore our blockchain courses</b></h4>
                        </div>
                        <div className='d-lg-flex'>
                            <div className='pt-4 col-lg-3 col-8 d-flex justify-content-center'>
                                {/* <div>
                                    <button className='btn-orange p-2 px-3 shadow rounded' onClick={handleScroll}><Link to="/course" className='text-light' target='_blank'>BUY NOW</Link></button>
                                </div> */}
                            </div>

                            <div className='d-lg-flex justify-content-between col-lg-9'>


                                <div className='p-3 col-12 col-md-8 col-lg-4 lic'>
                                    <div className='rounded-3 shadow p-4'>
                                        <div className='text-center'>
                                            <img src={beginer} alt='blockchain for beginner' className='cardImage' draggable="false" />
                                            <h4 className='py-2 card-text'>Blockchain for beginners</h4>
                                            <p className='py-2 text-dark'>6 Sessions</p>
                                            <button className='btn-orange-outline p-1 px-3 ' onClick={handleScroll}><Link to="/course"><b>LEARN MORE</b></Link></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-3 col-12 col-md-8 col-lg-4 coms'>
                                    <div className='rounded-3 shadow p-4'>
                                        <div className='text-center col-12'>
                                            <img src={intermediate} alt='blockchain for intermediate' className='cardImage' draggable="false" />
                                            <h4 className='py-2 card-text'>Blockchain for intermediate</h4>
                                            <p className='py-2 text-dark'>Loading ...</p>
                                            <button className='btn-orange-outline p-1 px-3'><b>COMING SOON</b></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-3 col-12 col-md-8 col-lg-4 coms'>
                                    <div className='rounded-3 shadow p-4'>
                                        <div className='text-center'>
                                            <img src={expert} alt='Blockchain for expert' className='cardImage' draggable="false" />
                                            <h4 className='py-2 card-text'>Blockchain for expert</h4>
                                            <p className='py-2 text-dark'>Loading ...</p>
                                            <button className='btn-orange-outline p-1 px-3'><b>COMING SOON</b></button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className='welcomeBg'>

                <div className='container-fluid py-5 col-lg-10 '>
                    <div className='p-3'>
                        <div className='container d-lg-flex justify-content-center '>
                            <div className='col-lg-6 col-md-10 d-flex justify-content-center'>
                                <img src={welcome} alt='About Probinar' className='col-11' draggable="false" />
                            </div>
                            <div className='col-lg-6'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='pt-5'>
                                        <p className='orange-text'><b>About Our Organization</b></p>
                                        <h2><b>Welcome to Probinar!</b></h2>
                                        <div>
                                            <p className='text-dark col-10'>
                                                Probinar, a product of UltraPro Blockchain Tech Pvt. Ltd., is dedicated to demonstrating the world of cryptocurrency and blockchain to ordinary individuals. Through our specialized courses, we equip individuals with the knowledge and insights needed to grasp how these technologies are reshaping the future.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 col-lg-10 hba '>
                <div className='p-3 px-2'>
                    <div className='container d-lg-flex'>
                        <div className='col-lg-6'>
                            <div className=''>
                                <h2 className='text-center'><b>Achievements Of Probinar</b></h2>
                                <div className='pt-4'>
                                    <p className='text-dark'>
                                        We've had the pleasure of educating over 1000+ students with Blockchain technology. A majority of our graduates have been easily placed into roles within Blockchain development companies with our effective training. Our dedicated instructors are highly knowledgeable about blockchain.
                                        We ensure that our lessons cover all the essential topics required for both newcomers and experienced individuals in the field.
                                    </p>
                                </div>
                                <div className=''>
                                    <div className='d-lg-flex justify-content-around '>
                                        <div className='d-flex justify-content-center '>
                                            <div className='card-right shadow rounded p-3 p-lg-4 text-center'>
                                                <b>1000+ Satisfied Students</b>
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-center pt-lg-0 pt-4  '>
                                            <div className='card-right shadow rounded p-3 px-4 p-lg-4 text-center'>
                                                <b>20+ Qualified Trainers</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4 '>
                                        <div className='d-flex justify-content-center '>
                                            <div className='card-right shadow rounded col-lg-5 p-3 p-lg-4 text-center'>
                                                <b>5000+ Sessions Watched</b>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className='col-lg-6 d-flex justify-content-center'>
                            <img src={achieve} alt='Achievements of Probinar' draggable="false" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 col-lg-10 hbb'>
                <div className='p-3'>
                    <div className='container'>
                        <div className='text-center'>
                            <h5 className='orange-text'>Popular Courses</h5>
                        </div>
                        <div>
                            <div className='text-center pb-3'>
                                <h2><b>Livestream online courses at Probinar</b></h2>
                            </div>
                        </div>

                        <div className='d-lg-flex justify-content-around lic'>
                            <div className='py-3 '>
                                <div className='d-flex justify-content-center'>
                                    <div className='shadow rounded-3 p-lg-3 mx-3 coms'>
                                        <div className='d-flex justify-content-center'>
                                            <img src={card1} alt="blockchain for beginner" draggable="false" />
                                        </div>
                                        <p className='text-center my-3 text-dark'><b>Beginner Level: Certified Blockchain Essentials</b></p>
                                        <ul className='text-dark demo'>
                                            <li className='text-dark'>Interactive live training for blockchain beginners.</li>
                                            <li className='text-dark'>Thoughtfully designed for enthusiasts entering the blockchain space.</li>
                                        </ul>
                                        <div className='d-flex justify-content-center pb-3 abk'>
                                            <button className='p-1 px-3'><Link to="https://hub.probinar.in/#/signup"><b>ENROLL NOW</b></Link></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className='d-flex justify-content-center'>
                                    <div className='shadow rounded-3 p-lg-4 mx-3 coms'> {/* Added mx-3 for horizontal margin */}
                                        <div className='d-flex justify-content-center'>
                                            <img src={card2} alt="blockchain for intermediate" draggable="false" />
                                        </div>
                                        <p className='text-center my-3 text-dark'><b>Intermediate Level: Certified Blockchain Practitioner</b></p>
                                        <ul className='text-dark demo'>
                                            <li className='text-dark'>Elevate your skills with our interactive live training.</li>
                                            <li className='text-dark'>Tailored for those seeking intermediate-level blockchain expertise</li>
                                        </ul>
                                        <div className='d-flex justify-content-center pb-2'>
                                            <button className='btn-orange-outline p-1 px-3'><b>COMING SOON</b></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='py-3'>
                                <div className='d-flex justify-content-center'>
                                    <div className='shadow rounded-3 p-lg-4 mx-3 coms'> {/* Added mx-3 for horizontal margin */}
                                        <div className='d-flex justify-content-center'>
                                            <img src={card3} alt="blockchain for expert" draggable="false"/>
                                        </div>
                                        <p className='text-center my-2 text-dark '><b> Expert Level: Certified Blockchain Architect</b></p>
                                        <ul className='text-dark demo'>
                                            <li className='text-dark'> Advanced live training for seasoned professionals.</li>
                                            <li className='text-dark'> Designed to deepen your  understanding of blockchain architecture.</li>
                                        </ul>
                                        <div className='d-flex justify-content-center pb-3'>
                                            <button className='btn-orange-outline p-1 px-3'><b>COMING SOON</b></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className='welcomeBg mt-5'>
                <div className='container-fluid py-5 col-lg-10 '>
                    <div className='p-'>
                        <div className='container d-lg-flex '>
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={insights} alt='Insights of Probinar' className='col-10' draggable="false"/>
                            </div>
                            <div className='col-lg-6'>
                                <div className='d-flex justify-content-center align-items-center h-100'>
                                    <div>
                                        <div className='text-center'>
                                            <h2 className='pt-3'><b>Insights of Probinar</b></h2>
                                        </div>
                                        <div>
                                            <p className='text-dark col-lg-10 pt-3'>
                                                We're all about being ahead in the world of blockchain tech.
                                                We teach people about blockchain,
                                                virtual assets, and decentralized apps in a way that's easy to understand.
                                            </p>
                                            <p className='text-dark col-lg-10 pt-3'>
                                                Our goal is to make blockchain simple for everyone through blockchain courses.
                                                Whether you're new to it or already know a bit, we're here to help you learn and grow
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 col-lg-10 hbc'>
                <div className='p-3'>
                    <div className='container d-flex justify-content-center'>
                        <div className='col-12 d-flex justify-content-center '>
                            <div className='col-12'>
                                <h2 className='p-4'><b>What do we provide</b></h2>

                                <div className='d-lg-flex justify-content-around'>
                                    <p className='provide-icon1 rounded-2 shadow p-4 text-center col-lg-3 col-md-8 text-dark'>Learn about Cryptocurrencies</p>
                                    <p className='provide-icon2 rounded-2 shadow p-4 text-center col-lg-3 col-md-8 text-dark'>About Blockchain Technology</p>
                                    <p className='provide-icon3 rounded-2 shadow p-4 text-center col-lg-3 col-md-8 text-dark'>Core Components of Blockchain</p>
                                </div>

                                <div className='d-lg-flex justify-content-around pt-3'>

                                    <p className='provide-icon4 rounded-2 shadow py-2 p-4 text-center col-lg-3 col-md-8 text-dark'>Investment strategies in cryptocurrency</p>
                                    <p className='provide-icon5 rounded-2 shadow p-2 pt-3 text-center col-lg-3 col-md-8 text-dark '>Blockchain Development and <span className='d-block col-6 text-dark text-start  ps-5'>Tools</span></p>
                                    <p className='provide-icon6 rounded-2 shadow p-4 text-center col-lg-3 col-md-8 text-dark'>Challenges and Future Trends</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-5 col-12 provideBg'>
                <div className='p-3'>
                    <div className='container d-flex justify-content-center'>
                        <div>

                            <div className='d-lg-flex'>
                                <div className='col-lg-4'>
                                    <div className='d-flex justify-content-center'>
                                        <img src={understanding} alt='Understanding Blockchain ' draggable="false"/>
                                    </div>
                                </div>
                                <div className='col-lg-8 d-flex align-items-center'>
                                    <div className='text-start pt-3'>
                                        <h3><b>Understanding Blockchain with Probinar</b></h3>
                                        <p className='text-dark col-10 pt-4'>
                                            Blockchain is a unique networking and data architecture,
                                            a configuration of computers forming a distributed database known as a "ledger."
                                            This ledger is shared across communication points (nodes)
                                            in a network, also termed Distributed Ledger Technology (DLT).
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='d-lg-flex '>
                        <div className='col-lg-8 d-flex align-items-center justify-content-center'>
                            <div className='text-center'>
                                <h3 className=''><b>How does it work?</b></h3>
                                <div className='d-flex justify-content-center'>
                                    <p className='text-dark col-lg-8 col-10 pt-4'>
                                        Unlike traditional databases, blockchain organizes information into blocks, each with a capacity limit. As a block reaches its limit, it closes and links to the last block, creating a chain. This process repeats, sequentially growing the blockchain. The decentralized network ensures each blockchain has its updated and newly added nodes. At Probinar, we simplify this process through a Blockchain online course, making blockchain accessible and understandable for all
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex justify-content-center'>
                            <img src={work} alt='How does it work' />
                            </div>
                        </div>

                    </div> */}

                            <div className='d-lg-flex '>
                                <div className='col-lg-4 order-lg-2'>
                                    <div className='d-flex justify-content-center'>
                                        <img src={work} alt='How blockchain works' draggable="false"/>
                                    </div>
                                </div>
                                <div className='col-lg-8 order-lg-1 d-flex align-items-center justify-content-center'>
                                    <div className='text-center'>
                                        <h3 className=''><b>How does it work?</b></h3>
                                        <div className='d-flex justify-content-center'>
                                            <p className='text-dark col-lg-8 col-10 pt-4'>
                                                Unlike traditional databases, blockchain organizes information into blocks, each with a capacity limit. As a block reaches its limit, it closes and links to the last block, creating a chain. This process repeats, sequentially growing the blockchain. The decentralized network ensures each blockchain has its updated and newly added nodes. At Probinar, we simplify this process through a Blockchain online course, making blockchain accessible and understandable for all
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='d-lg-flex '>
                                <div className='col-lg-4'>
                                    <div className='d-flex justify-content-center'>
                                        <img src={learning} alt='Why Learn Blockchain?' draggable="false"/>
                                    </div>
                                </div>
                                <div className='col-lg-8 d-flex align-items-center'>
                                    <div className='text-start'>
                                        <h3 className='pt-3'><b>Why Learn Blockchain?</b></h3>
                                        <p className='text-dark col-lg-8 pt-4'>
                                            Firstly, it presents financial opportunities, offering potential gains through investment. Secondly, as these technologies become increasingly relevant, understanding them prepares individuals for future career prospects. Additionally, learning about these innovations enhances technological literacy, empowering individuals to navigate the digital world confidently. Lastly, understanding cryptocurrency and blockchain allows individuals to stay informed about the evolving digital landscape and its potential impact on various industries.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 hbd'>
                <div className='p-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 mb-4 hcmbl'>
                                <div className='rounded  bg-light p-3 twoCard'>
                                    <h4 className='text-center'><b>Probinar's Professional Certification Benefits</b></h4>
                                    <div className='d-flex justify-content-center pt-3'>
                                        <p className='pb-4 col-lg-8 text-dark'>Certify your expertise and advance your skills in the field of Blockchain technology.</p>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3 '>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Upgrade Blockchain Skills</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Boost Employability </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Hands-On Business Application Implementation</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b> Lifetime Access to Training Videos</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon' >
                                                    <p className='text-dark'><b> Public Recognition on Website </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b> Explore Blockchain Use Cases</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b> Explore Blockchain Use Cases</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b> Ongoing Assistance Through Training</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 hcmbl'>
                                <div className='rounded twoCard  bg-light p-3 nmcns'>
                                    <h4 className='text-center'><b>Highlights of our course</b></h4>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Beginner, Intermediate, and Advanced Levels.</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Practical Application of Blockchain Education</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Great course at Affordable Cost</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-3 px-4 nmcon'>
                                                    <p className='text-dark'><b>Course completion Certificate with NFT security</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-2 px-4 nmcon'>
                                                    <p className='text-dark'><b>Guided Online Live Sessions with Expert Mentors.</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-2 px-4 nmcon'>
                                                    <p className='text-dark'><b>Providing 8 different sessions </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-2 px-4 nmcon'>
                                                    <p className='text-dark'><b>24/7 customer support</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-2 px-4 nmcon'>
                                                    <p className='text-dark'><b>Official Course Material with Quiz at Each Chapter's End.</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center nmcns'>
                                        <div className='col-10'>
                                            <div className='d-flex p-3'>
                                                <img src={rightarrow} alt='right arrow' draggable="false"/>
                                                <div className='d-flex align-items-center pt-2 px-4 nmcon'>
                                                    <p className='text-dark'><b>Learn with Real-World Examples and Case Studies.</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 hbe'>
                <Carousel data-bs-theme="dark">
                    <Carousel.Item>

                        <Carousel.Caption className="text-center">
                            <div>
                                <div className='pb-lg-5'>
                                    <img src={students1} alt='Dinesh' className="col-3 pt-5" draggable="false"/>
                                </div>
                                <div className='pb-lg-5 d-flex justify-content-center'>
                                    <h5 className='col-8 col-lg-10 col-md-8 '>
                                        I enrolled in Probinar's beginner course and was impressed
                                        by how clear and engaging the lessons were. Great value for money.
                                    </h5>
                                </div>
                                <div className='pb-lg-5'>
                                    <div className='text-center'>
                                        <h3>Dinesh</h3>
                                        <p className='orange-text'>Blockchain Student</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>

                        <Carousel.Caption className="text-center">
                            <div>
                                <div className='pb-lg-5'>
                                    <img src={students2} alt='Hrithik Roshan' className="col-4" draggable="false"/>
                                </div>
                                <div className='pb-lg-5 d-flex justify-content-center'>
                                    <h5 className='col-8 col-lg-10 col-md-8 '>
                                        Probinar's certification program helped me
                                        land a job in blockchain development. Thank you
                                        for your valuable skills and support.
                                    </h5>
                                </div>
                                <div className='pb-lg-5'>
                                    <div className='text-center'>
                                        <h3>Hrithik Roshan</h3>
                                        <p className='orange-text'>Blockchain Student</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>

                        <Carousel.Caption className="text-center">
                            <div>
                                <div className='pb-lg-5'>
                                    <img src={students3} alt='Pravin Kumar' className="col-4" draggable="false"/>
                                </div>
                                <div className='pb-lg-5 d-flex justify-content-center'>
                                    <h5 className='col-8 col-lg-10 col-md-8 '>
                                        I love the flexibility of Probinar's live courses.
                                        Being able to learn at my own pace has been incredibly
                                        beneficial. Thank you, Probinar.
                                    </h5>
                                </div>
                                <div className='pb-lg-5'>
                                    <div className='text-center'>
                                        <h3>Pravin Kumar</h3>
                                        <p className='orange-text'>Blockchain Student</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>

                        <Carousel.Caption className="text-center">
                            <div>
                                <div className='pb-lg-5'>
                                    <img src={students4} alt='Hrithik' className="col-3 pt-5" draggable="false"/>
                                </div>
                                <div className='pb-lg-5 d-flex justify-content-center'>
                                    <h5 className='col-8 col-lg-10 col-md-8 '>
                                        I have completed my blockchain course in Probinar and gave an outstanding performance and right now, I am also a team member with the extraordinary Probinar’s team.
                                    </h5>
                                </div>
                                <div className='pb-lg-5'>
                                    <div className='text-center'>
                                        <h3>Hrithik</h3>
                                        <p className='orange-text'>Blockchain Student</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>

                        <Carousel.Caption className="text-center">
                            <div>
                                <div className='pb-lg-5'>
                                    <img src={students5} alt='Ajay' className="col-4" draggable="false"/>
                                </div>
                                <div className='pb-lg-5 d-flex justify-content-center'>
                                    <h5 className='col-8 col-lg-10 col-md-8'>
                                        Probinar's courses are well-structured. I appreciate the focus on real-world applications and hands-on learning.
                                    </h5>
                                </div>
                                <div className='pb-lg-5'>
                                    <div className='text-center'>
                                        <h3>Ajay</h3>
                                        <p className='orange-text'>Blockchain Student</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>

                        <Carousel.Caption className="text-center">
                            <div>
                                <div className='pb-lg-5'>
                                    <img src={students6} alt='Gopi' className="col-3 pt-5" draggable="false"/>
                                </div>
                                <div className='pb-lg-5 d-flex justify-content-center'>
                                    <h5 className='col-8 col-lg-10 col-md-8'>
                                        I'm impressed by Probinar's dedication to making blockchain
                                        education accessible to everyone. Their affordable pricing
                                        and flexible learning options make it easy for anyone to
                                        get started.
                                    </h5>
                                </div>
                                <div className='pb-lg-5'>
                                    <div className='text-center'>
                                        <h3>Gopi</h3>
                                        <p className='orange-text'>Blockchain Student</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>

                    </Carousel.Item>
                </Carousel>
            </div>


            <div className='container-fluid py-5'>
                <div className='p-3'>
                    <div className='container'>
                        <div className='d-lg-flex'>
                            <div className='col-lg-4 order-lg-2'>
                                <img src={bottomImage} alt='why choose probinar' draggable="false"/>
                            </div>
                            <div className='col-lg-7 order-lg-1'>
                                <div className='d-flex justify-content-center'>
                                    <div className='text-start'>
                                        <h2 className='col-lg-8 pb-4'><b>Why choose Probinar for the blockchain course</b></h2>
                                        <p className='text-dark col-lg-8 pt-3'>
                                            Learn about the full potential of blockchain with Probinar's premier
                                            online blockchain course. Our comprehensive curriculum covers
                                            every aspect of blockchain technology, ensuring you gain the knowledge
                                            and skills needed to succeed in this rapidly evolving field.
                                        </p>

                                        <p className='text-dark col-lg-8 pt-3'>
                                            By enrolling in our blockchain course, you'll benefit from expert
                                            instruction, hands-on learning experiences, and personalized support
                                            every step of the way.

                                        </p>

                                        <p className='text-dark col-lg-8 pt-3'>
                                            Whether you're new to blockchain or looking to advance your skills,
                                            Probinar provides the perfect platform to achieve your goals. Join us
                                            today and embark on your journey to becoming a certified blockchain
                                            professional with our top-rated live online blockchain course

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='outers'>
                <div className='container'>
                    <div className='oters_c'>
                        <h1>Total Visitors Count</h1>
                        <h2>{count}</h2>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    );
};

export default Hero;